import React from "react";
import LocalesComponent from "./components/LocalesComponent/LocalesComponent";
import MainContentComponent from "./components/MainContentComponent/MainContentComponent";
import { apiGetLocalisation } from "api/index";
import { ProviderContextTranslate } from "context/ContextTranslate/ContextTranslate";
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory, useParams } from "react-router-dom";
import { languagesCodes } from "api/core";

// const is_MobileDevice = () => {
//     var check = false;
//     // @ts-ignore
//     var a = navigator.userAgent || navigator.vendor || window.opera;
//     if (
//         /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
//             a,
//         ) ||
//         /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
//             a.substr(0, 4),
//         )
//     ) {
//         check = true;
//     }
//     return check;
// };
//
// const is_MaxTouchPoints = () => {
//     return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
// };
//
// const is_MaxTouchPointsAll = () => {
//     return navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
// };

const PageRoot = () => {
    const routeParams = useParams<{ locale: string }>();

    const links = {
        tiktok: "https://www.tiktok.com/@official_standoff_2",
        youtube: "https://www.youtube.com/channel/UCqTjE54bhQ0tvtxaHHHM-hA",
        discord: "http://discord.gg/standoff2",
        twitter: "https://twitter.com/so2_official",
        vk: "https://vk.com/standoff2_official",
    };
    return (
        <div className="App container">
            <LocalesComponent />
            <div className="main-container">
                <div className="content">
                    <MainContentComponent />
                    <div className="social-links-desktop">
                        <a target="_blank" href={links.tiktok}>
                            <img className="social-link-icon" src="/icons/tiktok.png" alt="tiktok" />
                        </a>
                        <a target="_blank" href={links.youtube}>
                            <img className="social-link-icon" src="/icons/youtube.png" alt="youtube" />
                        </a>
                        <a target="_blank" href={links.discord}>
                            <img className="social-link-icon" src="/icons/discord.png" alt="discord" />
                        </a>
                        <a target="_blank" href={links.twitter}>
                            <img className="social-link-icon" src="/icons/twitter.png" alt="twitter" />
                        </a>
                        <a target="_blank" href={links.vk}>
                            <img className="social-link-icon" src="/icons/vk.png" alt="vk" />
                        </a>
                        <img
                            onClick={() => {
                                window.location.href = "https://axlebolt.com/";
                            }}
                            className="social-link-axlebolt"
                            src={"/icons/axlebolt-vertical.png"}
                            alt=""
                        />
                    </div>
                    <div className="links mobile">
                        <img
                            onClick={() => {
                                window.location.href = "https://axlebolt.com/";
                            }}
                            className="social-link-axlebolt"
                            src="/icons/axlebolt-horizontal.png"
                            alt=""
                        />
                        <div className="social-links-mobile">
                            <a target="_blank" href={links.tiktok}>
                                <img className="social-link-icon" src="/icons/tiktok.png" alt="tiktok" />
                            </a>
                            <a target="_blank" href={links.youtube}>
                                <img className="social-link-icon" src="/icons/youtube.png" alt="youtube" />
                            </a>
                            <a target="_blank" href={links.discord}>
                                <img className="social-link-icon" src="/icons/discord.png" alt="discord" />
                            </a>
                            <a target="_blank" href={links.twitter}>
                                <img className="social-link-icon" src="/icons/twitter.png" alt="twitter" />
                            </a>
                            <a target="_blank" href={links.vk}>
                                <img className="social-link-icon" src="/icons/vk.png" alt="vk" />
                            </a>
                        </div>

                        <div className="Eurostile footer-mobile">
                            <p>©AXLEBOLT</p>
                        </div>
                    </div>
                </div>
                <div className="Eurostile footer-desktop">
                    <p>©AXLEBOLT</p>
                </div>
            </div>
        </div>
    );
};

// const parseLocale = () => {
//     const f = window.location.pathname.split("/");
//     if (!f || f.length <= 1) {
//         return "en";
//     }
//     return ["ru", "en"].indexOf(f[1]) !== -1 ? f[1] : "en";
// };

function App() {
    const [dictionary, $dictionary] = React.useState<Record<string, string>>({});
    const [inProgressDictionary, $inProgressDictionary] = React.useState(true);

    const routeParams = useParams<{ locale: string }>();

    React.useEffect(() => {
        const locale = languagesCodes.indexOf(routeParams.locale) !== -1 ? routeParams.locale : "en";
        apiGetLocalisation(locale)
            .then((response: any) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return response.text();
                }
            })
            .then((response: Record<string, string>) => {
                $dictionary(response);
            })
            .catch((e: any) => {
                console.log(e);
            })
            .finally(() => {
                $inProgressDictionary(false);
            });
    }, [routeParams.locale]);

    return (
        <React.Fragment>
            <ProviderContextTranslate value={dictionary}>
                <PageRoot />
            </ProviderContextTranslate>
        </React.Fragment>
    );
}

export default App;
