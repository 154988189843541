import ky from "ky";

// function authenticateUser(user: string, password: string) {
//     var token = user + ":" + password;
//
//     var hash = btoa(token);
//
//     return "Basic " + hash;
// }

export const BACKEND_HOST = "https://install.standoff2.com";

export const languages: Record<string, string> = {
    en: "English",
    ru: "Русский",
    tr: "Turkish",
    pt: "Portuguese",
    br: "Brazil",
    es: "Spanish",
    hi: "Hindi",
    de: "German",
    th: "Thai",
    fr: "French",
    "zh-CN": "Chinese (Simplified)",
    "zh-TW": "Chinese (Traditional)",
    ko: "Korean",
    it: "Italian",
    pl: "Polish",
    ja: "Japanese",
};

export const languagesCodes: Array<string> = [
    "ru",
    "en",
    "tr",
    "pt",
    "br",
    "es",
    "hi",
    "de",
    "th",
    "fr",
    "zh-CN",
    "zh-TW",
    "ko",
    "it",
    "pl",
    "ja",
];

// "en", "ru", "tr", "pt", "es", "hi", "de", "th", "fr", "zh-CN", "zh-TW", "ko", "it", "pl", "ja"

export const getLanguageName = (l: string) => {
    return languages.hasOwnProperty(l) ? languages[l] : "English";
};

export const kyRequest = ky.extend({
    retry: {
        limit: 0,
    },
    timeout: 30000,
    throwHttpErrors: false,
    hooks: {
        beforeRetry: [
            (options) => {
                // console.log("beforeRetry::", options);
            },
        ],
        afterResponse: [
            async (request, options, response) => {
                // const renewedToken = response.headers.get("X-Token");
                // if (renewedToken) {
                //     localStorage.setItem("bearer_token", renewedToken);
                // }
                //
                // const body = await response.json();
                // if (response.url.includes("/game/events/") && response.url.includes("/players/") && response.status === 404) {
                // } else {
                //     store.dispatch(
                //         on_InsertNotice_Notify({
                //             request: func_TakeNativeRequest(request),
                //             response: func_TakeNativeReponse(response),
                //             body: body,
                //         }),
                //     );
                // }
                //
                // if (response.status === 403) {
                //     // const result = await response.json();
                //     // --------
                //     // error: "Forbidden"
                //     // message: ""
                //     // path: "/bolt-console/players/search"
                //     // status: 403
                //     // timestamp: "2021-07-29T10:23:12.967+00:00"
                //     // --------
                // }
                if (response.status === 500) {
                    // const result = await response.json();
                    // if (body.error && body.error.message && body.error.message === "JWT token is expired or invalid.") {
                    //     if (!response.url.endsWith("/project/info")) {
                    //         if (controller_ENV.query("REACT_APP_DISABLE_AUTH")) {
                    //         } else {
                    //             window.location.href = "/auth/login";
                    //         }
                    //     }
                    // }
                    // data: null
                    // error.code: 500
                    // error.message: "JWT token is expired or invalid."
                    // error.validationErrors: null
                    // success: false
                    // store.dispatch(
                    //   on_Predator({
                    //     body: result,
                    //     bodyUsed: response.bodyUsed,
                    //     headers: response.headers,
                    //     ok: response.ok,
                    //     redirected: response.redirected,
                    //     status: response.status,
                    //     statusText: response.statusText,
                    //     type: response.type,
                    //     url: response.url,
                    //   }),
                    // );
                }
            },
        ],
        beforeRequest: [
            (request) => {
                // @ts-ignore
                // ym(process.env.REACT_APP_YANDEX_METRIKA, "getClientID", function (clientID) {
                //     request.headers.set("YandexClientId", clientID);
                // });
                request.headers.set("Content-Type", "application/json");

                // if (!controller_ENV.query("REACT_APP_DISABLE_AUTH")) {
                //     if (
                //         !request.url.includes("/auth/login") &&
                //         !request.url.includes("/user/register") &&
                //         !request.url.includes("/user/create") &&
                //         !request.url.includes("/auth/password/reset") &&
                //         !request.url.includes("/auth/password/save") &&
                //         // !request.url.includes("/auth/password/update") &&
                //         !request.url.includes("/auth/reset/token/validate") &&
                //         !request.url.includes("/env/env.json") &&
                //         !request.url.includes("/version.json") &&
                //         !request.url.includes("/locale/ru.json") &&
                //         !request.url.includes("/auth/user/activate")
                //     ) {
                //         request.headers.set("X-Authorization", `Bearer_${localStorage.getItem("bearer_token") || ""}`);
                //     }
                // } else {
                //     if (
                //         !request.url.includes("/env/env.json") &&
                //         !request.url.includes("/version.json") &&
                //         !request.url.includes("/locale/ru.json")
                //     ) {
                //         request.headers.set("X-Authorization", `Bearer_${localStorage.getItem("bearer_token") || ""}`);
                //     }
                // }
            },
        ],
    },
});
