import React from "react";

export const ContextTranslate = React.createContext(undefined as any);
ContextTranslate.displayName = "CacheAccountContext";

export const ProviderContextTranslate = ContextTranslate.Provider;

type type_CacheAccount = Record<string, string>;

export function useContextLocale(): type_CacheAccount {
    return React.useContext<any>(ContextTranslate);
}

export const t = (k: string, d: Record<string, string>) => {
    return d.hasOwnProperty(k) ? d[k] : k;
};
