import React from "react";
import Button from "../Button/Button";
import { useContextLocale, t } from "context/ContextTranslate/ContextTranslate";
import { useParams } from "react-router-dom";

const MainContentComponent = () => {
    const d = useContextLocale();
    const routeParams = useParams<{ 0: string; locale: string }>();
    return (
        <div className="main-content">
            <img src={"/Logo.png"} alt="logo" className="App-logo" />
            <p className="Eurostile title">{t("JoinToMessage", d)}</p>
            <a
                className="button"
                href="https://play.google.com/store/apps/details?id=com.axlebolt.standoff2&hl=ru&gl=US"
            >
                <div className="button-content">
                    <img src={"/icons/googlePlay.png"} alt={"Google Play"} />
                    <div className="button-title">
                        <p className="load-in">{t("download_in", d)}</p>
                        <p className="store Eurostile">Google Play</p>
                    </div>
                </div>
            </a>
            <a className="button" href="https://appgallery.huawei.com/app/C102543465">
                <div className="button-content">
                    <img src={"/icons/app-gallery.png"} alt={"App Gallery"} />
                    <div className="button-title">
                        <p className="load-in">{t("download_in", d)}</p>
                        <p className="store Eurostile">AppGallery</p>
                    </div>
                </div>
            </a>
            <a className="button" href="https://apps.apple.com/ru/app/standoff-2/id1359706682">
                <div className="button-content">
                    <img src={"/icons/app-store.png"} alt={"App Store"} />
                    <div className="button-title">
                        <p className="load-in">{t("download_in", d)}</p>
                        <p className="store Eurostile">App Store</p>
                    </div>
                </div>
            </a>
            <a
                style={{ display: "block" }}
                className="bottom-button"
                href={`standoff2://${routeParams.locale}/${routeParams[0]}`}
            >
                <Button>
                    <span className="bottom-button-text EurostileMed">{t("AlreadyHaveGame", d)}</span>
                </Button>
            </a>
        </div>
    );
};

export default MainContentComponent;
