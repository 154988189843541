import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory, useParams } from "react-router-dom";
import App from "./App";

// const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Switch>
                <Route path="/:locale/*" component={App} />
                <Redirect to="/ru/" />
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById("root"),
);
