import * as React from "react";
import { ReactNode } from "react";

const Button: React.FC<{ onClick?: any; children: ReactNode }> = ({ children, onClick = () => {} }) => {
    return (
        <button onClick={onClick} className="button" style={{ margin: "5px" }}>
            {children}
        </button>
    );
};

export default Button;
