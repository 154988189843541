import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { getLanguageName, languages } from "api/core";

const LocalesComponent = () => {
    const [localesListOpen, $_localesListOpen] = React.useState(false);
    const routeParams = useParams<{ 0: string; locale: string }>();
    React.useEffect(() => {
        $_localesListOpen(false);
    }, [routeParams.locale]);
    return (
        <div className="locales-container">
            <div className="locales-block" onClick={() => $_localesListOpen(!localesListOpen)}>
                <span className="locale-title Eurostile">{getLanguageName(routeParams.locale)}</span>
                <div className="caret" />
                {localesListOpen && (
                    <div className="locales-list">
                        {Object.keys(languages).map((lan, key) => {
                            return (
                                <NavLink to={`/${lan}/`} key={key} className="locale-title Eurostile">
                                    {getLanguageName(lan)}
                                </NavLink>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LocalesComponent;
